<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Onshore Candidate</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Onshore Candidate
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createUser()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add New
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchUser"
                      @input="search.info = $event !== null ? $event : ''"
                      label="Lookup by Name, Unique Identifier, Email or Phone"
                      v-model="search.info" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchUser"
                      @input="search.mobile = $event !== null ? $event : ''"
                      label="Phone / Mobile No."
                      v-model="search.mobile" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="statuses"
                      v-model="search.candidate_status"
                      label="Candidate status"
                      item-text="title"
                      item-value="id"
                      outlined
                      v-on:keyup.enter="searchUser"
                      dense clearable
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchUser"
                      dense clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn
                      :loading="isLoading"
                       @click="searchUser"
                       class="mt-1 btn btn-primary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="text-left">
                    <th class="px-3" ><strong>Unique Identifier</strong></th>
                    <th><strong>Last Name</strong></th>
                    <th class="px-3" :class="sortedClass('first_name')" @click="sortBy('first_name')"><strong>First Name</strong></th>
                    <th><strong>Email</strong></th>
                    <th><strong>Phone / Mobile</strong></th>
                    <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                    <th class="px-3"><strong>Additional Info</strong></th>
                    <th><strong>Entry date</strong></th>
                    <th><strong>Candidate status</strong></th>
                    <th><strong>Status</strong></th>
                    <th class="pr-3 text-center"><strong>Action</strong></th>
                  </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in sortedItems" :key="index" >
                    <td>
                      <a @click="studentProfile(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        <v-avatar
                          class="profile"
                          color="grey"
                          size="30"
                        >
                          <v-img v-if="item.image_path" :src="item.image_path['thumb']"></v-img>
                          <img
                            v-else
                            :alt="item.full_name"
                            :src="`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${item.full_name}`"
                          >
                        </v-avatar>
                       &nbsp;&nbsp; <a @click="studentProfile(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ item.unique_identifier }}
                        </a>
                      </a>

                    </td>

                    <td class="text-left">
                      {{ item.last_name }}
                    </td>

                    <td>
                      <a @click="studentProfile(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ item.first_name }}</a>
                    </td>

                    <td>
                      <a v-clipboard:copy="`${item.full_name} ${item.email}`"
                         v-clipboard:success="onCopy"
                         v-clipboard:error="onError">
                         {{ item.email }}
                      </a>

                    </td>

                    <td>
                      {{ item.phone || item.mobile }}
                    </td>

                    <td class="px-2" v-if="currentUser && currentUser.access_type == 'head'">{{item.branch_name}}</td>

                    <td>
                      <a @click="openDialog(item.candidate_inquiry_info_id)">Additional Info</a>
                    </td>

                    <td>
                      {{item.formatted_created_at}}
                    </td>

                    <td>
                      <span v-html="item.candidate_status_text"></span>
                    </td>

                    <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

                            <b-dropdown-text tag="div" class="navi-item" v-if="item.candidate_inquiry_info_id && checkIsAccessible('candidate-inquiry-info', 'create')">
                              <a @click="additionalInfo(item.candidate_inquiry_info_id)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-info</v-icon>
                                  </span>
                                <span class="navi-text">Addition info</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'edit')">
                              <a @click="editUser(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

<!--                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'edit')">-->
<!--                              <a @click="changePassword(item)" class="navi-link">-->
<!--                                  <span class="navi-icon">-->
<!--                                      <v-icon color="blue darken-2">fas fa-key</v-icon>-->
<!--                                  </span>-->
<!--                                <span class="navi-text">Change password</span>-->
<!--                              </a>-->
<!--                            </b-dropdown-text>-->


<!--                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('user', 'edit')">-->
<!--                              <a @click="studentProfile(item.id)" class="navi-link">-->
<!--                                  <span class="navi-icon">-->
<!--                                      <v-icon color="blue darken-2">fas fa-eye</v-icon>-->
<!--                                  </span>-->
<!--                                <span class="navi-text">View Profile</span>-->
<!--                              </a>-->
<!--                            </b-dropdown-text>-->

                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteUser(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash</v-icon>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0" >
                    <td colspan="9" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllUsers"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllUsers"></create-and-update>
      <user-password-change ref="user-password-change" @refresh="getAllUsers"></user-password-change>

      <v-dialog
          v-model="dialog"
          hide-overlay
          persistent
          width="300"
      >
        <v-card
            color="primary"
            dark
        >
          <v-card-text>
            Processing Your Request
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="dialogFutureLead"
          hide-overlay
          persistent
          width="800"
      >
        <v-card>
          <v-toolbar dark>
            <v-card-title class="text-h5">
              <span>Additional Info - {{candidateInquiryInfo.full_name}}</span>
              <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="dialogFutureLead = false">
                <v-icon color="black">mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <strong>
                    Applied College:
                  </strong>
                  {{ candidateInquiryInfo.applied_college || "N/A"}}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Applied Program:
                  </strong>
                  {{ candidateInquiryInfo.applied_program || "N/A"}}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Level of Program:
                  </strong>
                  {{ candidateInquiryInfo.education_level_title || "N/A"}}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Intake info:
                  </strong>
                  <span class="badge badge-success">
                      {{ candidateInquiryInfo.intake_info ? $moment(candidateInquiryInfo.intake_info).format('MMM YYYY') : "N/A" }}
                  </span>
                </v-col>

<!--                <v-col cols="12" md="6">-->
<!--                  <strong>-->
<!--                    Applied session :-->
<!--                  </strong>-->
<!--                  {{ candidateInquiryInfo.applied_session_title || "N/A"}}-->
<!--                </v-col>-->
                <v-col cols="12" md="6">
                  <strong>
                    Status:
                  </strong>
                  <span class="text-lg-body-1" v-html="candidateInquiryInfo.status_text"></span>
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    DLI change:
                  </strong>
                      {{ candidateInquiryInfo.dli_change || 'N/A' }}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Marital status:
                  </strong>
                      {{ candidateInquiryInfo.marital_status || 'N/A' }}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Passport status:
                  </strong>
                      {{ candidateInquiryInfo.passport_status || 'N/A' }}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Location:
                  </strong>
                      {{ candidateInquiryInfo.location || 'N/A' }}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Past consultancy:
                  </strong>
                      {{ candidateInquiryInfo.past_consultancy || 'N/A' }}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Study permit expired:
                  </strong>
                  <span class="badge badge-success">
                      {{ candidateInquiryInfo.formatted_visa_study_permit }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Current intake end date:
                  </strong>
                  <span class="badge badge-success">
                      {{ candidateInquiryInfo.formatted_current_intake_end_date }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Intended intake start date:
                  </strong>
                  <span class="badge badge-success">
                      {{ candidateInquiryInfo.formatted_intended_intake_start_date }}
                  </span>
                </v-col>

                <v-col cols="12" md="6" class="mt-2">
                  <strong>
                    Gap date:
                  </strong>
                  <span class="badge" v-bind:class="{'badge-success': countDate <= 150, 'badge-danger': countDate > 150}"> {{countDate}} days</span>
                </v-col>

                <v-col cols="12" md="12">
                  <strong>
                    Reason for contacting:
                  </strong>
                  <span v-html="candidateInquiryInfo.reason_of_changing"></span>
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Application instruction notes:
                  </strong>
                  <span v-html="candidateInquiryInfo.remarks"></span>
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Tuition payment deadline details:
                  </strong>
                  <span v-html="candidateInquiryInfo.want_to_apply"></span>
                </v-col>
                <v-col cols="12" md="12">
                  <strong>
                    Solution:
                  </strong>
                  <span v-html="candidateInquiryInfo.solution"></span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="checkIsAccessible('candidate-inquiry-info', 'edit')"
                class="btn btn-primary"
                @click="editCandidateInquiryInfo"
                :loading="loading"
            >
              Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import UserPasswordChange from "@/view/pages/view/user/general/PasswordUpdate";
import UserService from "@/services/user/UserService";
import CandidateInquiryInfo from "@/services/candidate-inquiry-info/CandidateInquiryInfo";
import VisaApplicationStatusService from "@/services/visa/application-status/VisaApplicationStatusService";
import VisaApplicationStatusStateService from "@/services/visa/application-status/VisaApplicationStatusStateService";

const user = new UserService();
const candidateInquiryInfo = new CandidateInquiryInfo();
const visaApplicationStatus = new VisaApplicationStatusService();
const statusStateService = new VisaApplicationStatusStateService();
export default {
  name: "Index",
  components: {
    CreateAndUpdate,
    UserPasswordChange
  },
  data() {
    return{
      loading: false,
      dialog: false,
      dialogFutureLead: false,
      isLoading: false,
      users: [],
      statuses: [],
      total: null,
      perPage: null,
      page: null,
      candidateInquiryInfo: {},
      visaApplicationStatuses: [],
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        { name: 'Active', value: '1'},
        { name: 'Inactive', value: '0'},
      ],
      search:{
        info:'',
        type:'',
        mobile: '',
        candidate_status: '',
        entry_type: 'lead',
        is_active: '1',
      },
    }
  },
  mounted() {
    this.getAllUsers();
    this.getApplicationState();
  },
  computed: {
    sortedItems() {
      const list = this.users.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    },
    countDate() {
      const current_intake_end_date = this.candidateInquiryInfo.current_intake_end_date ? this.$moment(this.candidateInquiryInfo.current_intake_end_date) : null;
      const intended_intake_start_date = this.candidateInquiryInfo.intended_intake_start_date ? this.$moment(this.candidateInquiryInfo.intended_intake_start_date) : null;
      if(current_intake_end_date && intended_intake_start_date) {
        return intended_intake_start_date.diff(current_intake_end_date, "days");
      }
      return 0;
    }
  },
  watch: {
    dialog (val) {
      if (!val) return
      setTimeout(() => (this.dialog = false), 4000)
    },
  },
  methods: {
    getApplicationState() {
      visaApplicationStatus.getByCountry(39, 'candidate_inquiry_info').then((response) => {
        this.visaApplicationStatuses = response.data.applications_statuses;
        if(this.visaApplicationStatuses) {
          this.getApplicationStateStatus();
        }
      }).catch(() => {});
    },

    getApplicationStateStatus() {
      statusStateService.getAllVisaStateStatus(this.visaApplicationStatuses[0].id).then((response) => {
        this.statuses = response.data.data;
      }).catch(() => {});
    },

    additionalInfo(id) {
      if(id) {
        this.$tabs.open({name: "candidate-inquiry-info-edit", params: {id: id}});
      } else {
        this.$tabs.open({name: "candidate-inquiry-info-create"});
      }
    },
    changePassword(item) {
      this.$refs['user-password-change'].changePassword(item);
    },

    openDialog(candidate_inquiry_info_id) {
      this.dialogFutureLead = true;
      this.getCandidateInquiryInfo(candidate_inquiry_info_id);
    },

    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },

    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },

    createUser(){
      this.$refs['create-and-update'].createUser();
    },

    editUser(item){
      this.$refs['create-and-update'].editUser(item);
    },

    editCandidateInquiryInfo(){
      this.$router.push({
        name: 'candidate-inquiry-info-edit',
        params:{ id: this.candidateInquiryInfo.id },
      })
    },

    studentProfile(id){
      this.$router.push({
        name:'student-profile',
        params:{user_id:id},
      })
    },

    getCandidateInquiryInfo(candidate_inquiry_info_id) {
      candidateInquiryInfo
          .show(candidate_inquiry_info_id)
          .then(response => {
            if(response.data.candidateInquiryInfo) {
              this.candidateInquiryInfo = response.data.candidateInquiryInfo;
            }
          }).catch(() => {
            this.candidateInquiryInfo = {};
      });
    },

    getAllUsers(){
      this.loading = true;
      user
          .paginate(this.search,this.page)
          .then(response => {
            this.users = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchUser(){
      this.getAllUsers();
    },

    updateFutureLead(item) {
      this.$confirm({
        message: `Are you sure you want update this ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            item.is_future_lead = !item.is_future_lead;
            user
                .update(item.id, item)
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("Update Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    deleteUser(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            user
                .delete(id)
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("Candidate deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    onCopy: function () {
      this.$snotify.success("Copy successfully");
    },

    onError: function () {
      this.$snotify.success("Failed to copy");
    },
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>